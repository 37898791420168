@import '~@inovirtue/styles';

.session-tile {
  width: 336px;
  height: 140px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: dist(12);
  border: 1px solid $gray-5;
  border-radius: dist(4);
  &__subtitle {
    @include subheading-16(400);
    margin-bottom: dist(3);
  }
  &__title {
    @include heading-20(500);
    margin-bottom: dist(3);
  }
  &__details {
    @include subheading-16(500);
    margin-bottom: 0;
  }
  &__error {
    color: $danger-8;
    margin: 0;
    margin-top: dist(4);
    @include body-14(400);
  }
  &--error {
    border-color: $danger-8;
  }

  &--active {
    transition: ease-in 0.5s;
    border: none;
    box-shadow: 4px 7px 22px -9px rgba(0, 0, 0, 1);
  }
  &--disabled {
    border-color: $gray-5;
    background: $gray-3;
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
    .session-tile {
      &__subtitle,
      &__title {
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }
}
