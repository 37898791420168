@import '@inovirtue/styles';

.providers-select-container {
  @include desktop {
    width: 516px;
  }
  display: flex;
  height: dist(16);
  position: relative;
  &__select {
    display: flex;
    width: 100%;
    &__icon {
      position: absolute;
      top: 8px;
      right: 10px;
    }
  }
  &__clear-popup {
    width: 416px;
    .ant-btn {
      text-transform: capitalize;
      border-radius: dist(16);
    }
  }
}
