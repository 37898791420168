@import '@inovirtue/styles';

.filter-coaches {
  &-container {
    display: flex;
    flex-direction: row;
    gap: $dist-10;
    padding-left: 0;

    &__tags {
      &--language {
        display: flex;
        flex-direction: row;
        gap: $dist-2;
        align-items: center;
      }
    }
  }
}