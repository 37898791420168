@import '~@inovirtue/styles';

.profileNavBar {
  display: flex;
  align-items: stretch;

  &__link {
    padding: 0 $dist-10;
    color: inherit;
  }

  &__link--active & {
    color: inherit;
    &__link-content {
      display: block;
      height: 100%;
      box-shadow: inset 0px (-$dist-1) 0px;
    }
  }
}
