@import '@inovirtue/styles';

.company-contact-form {
  &__submit {
    text-align: right;
  }
  &__position-select {
    z-index: 1000!important;
    .ant-btn {
      border: none;
      border-top: 1px solid $gray-5;
      border-radius: 0;
    }
  }
}
