@import '@inovirtue/styles';

.monthlySessions {
  display: flex;
  &__section {
    width: 316px;
    padding: $dist-16 0;
    margin-right: dist(28);
  }

  &__input.ant-input-number {
    width: 100%;
  }

  &__heading {
    @include heading-20;
  }

  &__subheading {
    @include body-14(400);
  }

  &__validation.ant-alert-error {
    margin-top: $dist-2;
  }
}
