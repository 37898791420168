@import '@inovirtue/styles';
.form-section {
  margin-bottom: dist(16);
  max-width: dist(200);
  &__header {
    @include heading-24(500);
  }

  &__date-picker {
    width: 100%;
  }
}
