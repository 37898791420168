$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

@mixin mobile-sm-landscape {
  @media screen and (min-height: #{$xs}) and (max-height: #{$sm}) {
    @content;
  }
}

@mixin mobile-sm {
  @media screen and (min-width: #{$xs}) and (max-width: #{$sm}) {
    @content;
  }
}

@mixin tablet-sm {
  @media screen and (min-width: #{$sm}) {
    @content;
  }
}

@mixin tablet-md {
  @media screen and (min-width: #{$md}) {
    @content;
  }
}

@mixin tablet-lg {
  @media screen and (min-width: #{$lg}) {
    @content;
  }
}
@mixin max-tablet-lg {
  @media screen and (min-width: #{$xs}) and (max-width: #{$sm}) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: #{$xl}) {
    @content;
  }
}

@mixin desktop-xl {
  @media screen and (min-width: #{$xxl}) {
    @content;
  }
}
