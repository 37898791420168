@import '@inovirtue/styles';

.waiting-for-employees {
  &__columns {
    &-name {
      display: flex;
      align-items: center;

      &-container {
        margin-left: $dist-4;
        &-name {
          @include body-14($font-medium);
        }
        &-email {
          @include body-14($font-normal);
        }
      }
    }
    &__actions {
      text-align: center;
    }
  }
}
