$base-distance: 2px;

@function dist($multiplier) {
  @return $base-distance * $multiplier;
}

$dist-1: dist(1);
$dist-2: dist(2);
$dist-3: dist(3);
$dist-4: dist(4);
$dist-5: dist(5);
$dist-6: dist(6);
$dist-7: dist(7);
$dist-8: dist(8);
$dist-9: dist(9);
$dist-10: dist(10);
$dist-11: dist(11);
$dist-12: dist(12);
$dist-13: dist(13);
$dist-14: dist(14);
$dist-15: dist(15);
$dist-16: dist(16);
$dist-17: dist(17);
$dist-18: dist(18);
$dist-19: dist(19);
$dist-20: dist(20);
$dist-21: dist(21);
$dist-23: dist(23);
$dist-24: dist(24);
$dist-25: dist(25);
$dist-28: dist(28);
$dist-30: dist(30);
$dist-32: dist(32);
$dist-33: dist(33);
$dist-34: dist(34);
$dist-37: dist(37);
$dist-38: dist(38);
$dist-39: dist(39);
$dist-40: dist(40);
$dist-44: dist(44);
$dist-45: dist(45);
$dist-46: dist(46);
$dist-48: dist(48);
$dist-50: dist(50);
$dist-55: dist(55);
$dist-60: dist(60);
$dist-64: dist(64);
$dist-100: dist(100);
$dist-150: dist(150);
