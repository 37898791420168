@import "./_breakpoints.module.scss";
@import "./_colors.scss";
@import "./_distance.scss";

html.inovirtue__document {
  font-size: 62.5%;
}

html.inovirtue--fullHeight,
body.inovirtue--fullHeight,
#root {
  height: 100%;
  margin: 0;

}


.content {
  margin: 0 auto;
  padding: 0 $dist-8;
  width: 100%;
  max-width: calc(1416px + (2 * $dist-8));
}

.content-wide {
  margin: 0 auto;
  width: 100%;
  max-width: 1580px;
}

.copyright-menu-item {
  white-space: normal;
  font-size: $dist-6;
}

.ant-list-empty-text {
  padding: 0;
}

.ant-avatar-square {
  background: $gray-4;
  border-radius: 8px;
}

.ant-menu {
  box-shadow: none;
}

.ant-form-item-required {
  .required-label {
    color: $color-text-secondary;
    white-space: pre;
  }
}
