@import '@inovirtue/styles';
.recommendedProviders {
  &__validation.ant-alert-error {
    width: 316px;
    margin: $dist-2 0;
  }
  &__provider_consumer-count {
    margin-left: dist(2);
  }
  &__results-count {
    color: $black-45;
    margin-top: $dist-8;
    margin-bottom: $dist-12;
  }
}
