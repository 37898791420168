
@import '@inovirtue/styles';

.partnerGeneralInfo {
    &__fieldLabel {
        border-top: 1px solid #eee;
        padding-top: dist(5);
    }

    &__images {
        --grid-layout-gap: 10px;
        --grid-column-count: 2;
        --grid-item--min-width: 160px;
      
        --gap-count: calc(var(--grid-column-count) - 1);
        --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
        --grid-item--max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count));

        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr));

    }
}