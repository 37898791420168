@import '@inovirtue/styles';

.providers-list {
  &__header {
    @include subheading-16($font-medium);
    display: flex;
    justify-content: space-between;
    padding: $dist-8;
  }

  &__search {
    width: 264px;
  }

  &__provider-name {
    @include unify-parent('span') {
      @include subheading-16;
      cursor: pointer;
    }
  }
  &__actions {
    display: flex;
  }
}
