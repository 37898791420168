@import '~@inovirtue/styles';
.group-add-members-view {
  display: flex;
  flex-flow: column;
  height: 100%;

  &__modal-content {
    > .popover__content-inner {
      padding-left: dist(126);
      height: 100%;
    }
  }
  &__save {
    float: right;
    align-self: flex-end;
  }
}
