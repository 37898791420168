@import '@inovirtue/styles';

.companyAssignment {

  padding: $dist-16 0;
  width: 316px;
  &__select {
    width: 100%;
  }

  &__heading {
    @include heading-20;
  }

  &__subheading {
    @include body-14(400);
  }

  &__validation.ant-alert-error {
    margin-top: $dist-2;
  }

}
