@import '~@inovirtue/styles';

.scheduleMeeting {
  padding-left: $dist-8;
  padding-right: $dist-8;
  width: 100%;
  @include tablet-lg {
    width: 768px;
  }

  @include desktop {
    margin-left: 130px;
  }
  &-alert {
    max-width: 516px;
    display: flex;
    align-items: center;
  }
  &-header {
    margin-bottom: $dist-8;
    &-title {
      margin-top: 0 !important;
      @include large-heading-38;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }
    &-duration {
      &__subTitle {
        margin-left: dist(5);
      }
      &__subTitle,
      .anticon {
        color: $gray-6;
      }
    }
    &-bookInfo {
      color: $gray-6;
    }
  }
}
