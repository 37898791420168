@import '@inovirtue/styles';

$btn-color: #141414;

.partnerCreateform {

    &__images {
        display: flex;
        margin-top: dist(10);
        width: 750px;
    }

    &__section {
        margin-bottom: dist(16);
        max-width: dist(200);
    }

    &__sectionHeader {
        @include heading-24(500);
    }

    &__fieldInfo {
        color: rgba(0, 0, 0, 0.45);
        margin-top: -45px;
    }

    &__submitButton {
        background: $btn-color;
        border: 1px solid #141414;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
        border-radius: dist(40);
    }

    &__item {
        margin-bottom: dist(5);
        margin-right: dist(2);
    }
    &__tags {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
    }
    &__input {
        width: dist(45);
    }

    &__input--disabled {
        background-color: #eee;
    }
}