@import '@inovirtue/styles';

.active-employees-list-columns {
  &-name {
    display: flex;
    align-items: center;

    &-container {
      margin-left: $dist-4;
    }
  }
}
