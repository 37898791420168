@import '@inovirtue/styles';

.invite-employees {
  &.ant-layout {
    background: white;
  }
  @include desktop {
    padding: 0 80px;
  }
  @include desktop-xl {
    padding: 0 250px;
  }
  &__employees-list {
    margin-top: $dist-24;
  }
  &-header {
    &__title {
      margin-bottom: $dist-16;
      @include large-heading-38(500);
    }
    &__info {
      @include subheading-16(normal);
      margin-bottom: $dist-8;
      max-width: 695px;
    }
  }
  &-content {
    &__company-select {
      &.ant-form-item {
        margin-top: dist(12);
        margin-bottom: dist(24);
        display: flex;
        flex-direction: column;
        max-width: dist(170);
        @include mobile-sm {
          width: 100%;
        }
        .ant-form-item-label {
          text-align: left;
        }
      }
    }
  }
  &__add-form {
    margin-bottom: $dist-50;
  }
}
