@import '@inovirtue/styles';

.avatar-collection {
  &__avatar-icon {
    svg {
      color: $yellow-7;
    }

    &-background {
      background-color: $yellow-2 !important;
      border: 1px solid $yellow-7 !important;
    }
  }

  &__name {
    @include body-14($font-medium);
    margin-left: $dist-2;
  }
}
