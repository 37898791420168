@import '~@inovirtue/styles';

.smallCalendar {
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $dist-6;
    &-month {
      margin-bottom: 0;
    }
    &-button {
      height: $dist-12;
      width: $dist-12;
      .anticon {
        font-size: $dist-6;
      }
    }
  }
}
