@import '~@inovirtue/styles';
.imageUpload {

    margin-right: dist(30);

    &__img {
        width: 100%;
    }

    &__description {
        width: 150px;
        font-size: dist(6);
    }
    &__title { 
        @include subheading-16(500);
    }
}