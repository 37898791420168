@import '~@inovirtue/styles';

.company-details-view {
  border-radius: $dist-4;
  background-color: $white;
  width: 456px;
  padding: $dist-15;
  height: fit-content;
  &__loader {
    display: flex;
    justify-content: center;
  }
}
.company-details-dashboard {
  display: flex;
  gap: $dist-12;
}
