@import '~@inovirtue/styles';

.rbc-event {
  height: $dist-25;
  background: transparent !important;
  border: none !important;

  .rbc-event-label {
    display: none;
  }
}
.schedulerEvent {
  background: $tomato-5;
  padding: $dist-1;
  border-radius: $dist-2;
  &-block {
    background-color: $gray-5;
    color: $black;
  }
  .avatar {
    height: $dist-12;
    border-radius: $dist-6;
    margin-right: $dist-4;
  }
}
