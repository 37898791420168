@import '@inovirtue/styles';

.partnersViewHeader {

    width: 100%;
    display: flex;
    align-items: center;
    margin-left: dist(10);
    margin-right: dist(10);
 
    &__button {
        margin-left: auto;
    }

    &__title {

        @include heading-20($font-bold);
        margin-top: dist(10);
        margin-bottom: 0;

    }

    &__description {
        @include subheading-16($font-normal);

    }
}