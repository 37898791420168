@import '@inovirtue/styles';

.profile-info {
  display: flex;
  flex-direction: row;
  align-items: center;

  :hover {
    cursor: pointer;
  }

  &__avatar {
    padding-right: 16px;
  }

  &__details {
    font-weight: $font-medium;
    flex-direction: row;
  }

  &__name {
    font-size: $dist-8;
    line-height: $dist-12;
  }

  &__role {
    font-size: $dist-6;
    line-height: $dist-10;
  }
}
