@import '~@inovirtue/styles';
.partner-details-tabs {
  width: 100%;
  & > .ant-tabs-nav {
    padding-left: dist(12);
    margin-bottom: 0 !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $color-text-primary;
  }
  .ant-tabs-ink-bar {
    background: $color-text-primary !important;
  }
}
