@import '@inovirtue/styles';
.workshops-list-view {
  &__header {
    display: flex;
    justify-content: space-between;
    &__text {
      @include heading-20;
    }
  }
  &__sub-header {
    @include subheading-16;
    color: $color-text-secondary;
    display: none; //it's 'very' temporary
  }

  &__content {
    padding: dist(12) 0;
  }
}
