@import '~@inovirtue/styles';

.chat-sidebar {
  &__tab-icon {
    font-size: $dist-12 !important;
  }

  &__new-message {
    padding: $dist-8;
  }
}
