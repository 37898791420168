@import '~@inovirtue/styles';
.sessions-slots-carousel {
  height: 260px;
  &.swiper-container {
    width: 85vw;
    margin: 0 !important;
    .swiper-wrapper {
      align-items: center;
    }
    &:after {
      content: '';
      position: absolute;
      width: dist(30);
      border-right: 1px solid $gray-5;
      background: linear-gradient(278.05deg, #ffffff 6.53%, rgba(255, 255, 255, 0) 94.21%);
      z-index: 1000;
      height: 100%;
      display: block;
      top: 0;
      right: 0;
    }
  }
  &__element {
    width: 336px !important;
    margin-right: $dist-12;
    height: auto;
  }
}
