@import '~@inovirtue/styles';

.partnerDetailsView {
  padding-bottom: dist(20);

  &__inactiveAlert {
    margin-bottom: dist(8);
    width: dist(150);
  }
    &__editBar {
        margin-bottom: dist(12);
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: dist(4);
        background-color: $white;
        padding: dist(8);
        &__text {
          @include body-14();
          color: $color-text-secondary;
        }
      }

    &__cardsContainer {
        width: 100%;
    }
} 