@import '@inovirtue/styles';

.companies-list {
  width: 100%;
  &__header {
    @include subheading-16($font-medium);
    display: flex;
    justify-content: space-between;
    padding: $dist-8;
  }
}
