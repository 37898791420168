@import '~@inovirtue/styles';

.search-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $dist-20;

  &__autocomplete {
    width: 100%;
  }

  &__spinner {
    margin: 0 auto;
  }

  &__text {
    margin: 0;
    max-width: dist(155);
    @include body-14;
    color: $color-text-secondary;
    text-align: center;
  }
}
