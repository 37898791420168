@import '@inovirtue/styles';

.group-form {
  height: 100%;
  margin-left: 252px;
  padding-bottom: dist(20);
  .form-section {
    max-width: 516px;
    &__subheader {
      @include subheading-16;
      color: $color-text-secondary;
    }
    .ant-input-number {
      width: 100%;
    }
  }
  &__submit {
    float: right;
  }
}
