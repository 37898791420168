$transparent: transparent;

$tomato-1: #ffecec;
$tomato-2: #ffcfcf;
$tomato-3: #feb4b5;
$tomato-4: #ff9091;
$tomato-5: #fd696a;
$tomato-6: #fc4445;
$tomato-7: #f00b0c;
$tomato-8: #c20203;
$tomato-9: #990102;
$tomato-10: #750102;
$tomato-11: #e8544e;

$green-1: #f6ffed;
$green-2: #d9f7be;
$green-3: #b7eb8f;
$green-4: #95de64;
$green-5: #73d13d;
$green-6: #52c41a;
$green-7: #389e0d;
$green-8: #237804;
$green-9: #135200;
$green-10: #092b00;

$yellow-1: #fff6e6;
$yellow-2: #ffedca;
$yellow-3: #ffdc99;
$yellow-4: #ffca66;
$yellow-5: #ffca66;
$yellow-6: #ffa800;
$yellow-7: #d38c02;
$yellow-8: #986400;
$yellow-9: #603f00;
$yellow-10: #603f00;

$orange-1: #fef6ed;
$orange-2: #fffbe6;
$orange-4: #ffe58f;
$orange-3: #faad14;
$orange-7: #d46b08;
$orange-8: #da6317;

$purple-1: #edebfe;
$purple-2: #dcdaf8;
$purple-3: #bfbcea;
$purple-4: #a4a0d9;
$purple-5: #8984c5;
$purple-6: #716bbb;
$purple-7: #5f57a6;
$purple-8: #4c4589;
$purple-9: #3f3b69;
$purple-10: #2d2a52;

$blue-1: #e6f7ff;
$blue-2: #bae7ff;
$blue-3: #91d5ff;
$blue-4: #69c0ff;
$blue-5: #40a9ff;
$blue-6: #1890ff;
$blue-7: #096dd9;
$blue-8: #0050b3;
$blue-9: #003a8c;
$blue-10: #002766;

$geek-blue-6: #2f54eb;

$danger-1: #ffe7e7;
$danger-2: #ffc0c0;
$danger-3: #ffa1a1;
$danger-4: #ff7c7c;
$danger-5: #ff4b4b;
$danger-6: #fa0000;
$danger-7: #dc0101;
$danger-8: #cc0000;
$danger-9: #a50000;
$danger-10: #8c0000;

$white: #ffffff;
$black: #000000;

$gray-2: #fafafa;
$gray-3: #f5f5f5;
$gray-4: #f0f0f0;
$gray-5: #d9d9d9;
$gray-6: #bfbfbf;
$gray-7: #8c8c8c;
$gray-8: #595959;
$gray-9: #434343;
$gray-10: #262626;
$gray-11: #1f1f1f;
$gray-12: #141414;
$gray-13: #f0f2f5;

$color-text-title: rgba($black, 0.85);
$color-text-primary: rgba($black, 0.85);
$color-text-secondary: rgba($black, 0.45);
$color-text-placeholder: rgba($black, 0.25);
$color-text-disabled: rgba($black, 0.25);
$color-text-danger: $danger-6;
$color-text-warning: $yellow-6;
$color-text-success: $green-6;

$black-15: rgba($black, 0.15);
$black-25: rgba($black, 0.25);
$black-45: rgba($black, 0.45);
$black-75: rgba($black, 0.75);
$black-85: rgba($black, 0.85);

$online-status-available: $green-6;
$online-status-busy: $danger-5;
$online-status-notavailable: $gray-7;

$primary-ultralight-grey: #e5e5e5;
