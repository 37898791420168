@use 'sass:selector';

@font-face {
  font-family: 'Gravitas One';
  src: url(https://fonts.gstatic.com/s/gravitasone/v10/5h1diZ4hJ3cblKy3LWakKQmqCm5M.woff2)
    format('woff2');
}
$secondary-font-family: 'Gravitas One';

$font-size-8: 0.8rem;
$font-size-9: 0.9rem;
$font-size-10: 1rem;
$font-size-12: 1.2rem;
$font-size-14: 1.4rem;
$font-size-16: 1.6rem;
$font-size-18: 1.8rem;
$font-size-20: 2rem;
$font-size-22: 2.2rem;
$font-size-25: 2.5rem;
$font-size-30: 3rem;
$font-size-32: 3.2rem;
$font-size-38: 3.8rem;
$font-size-48: 4.8rem;
$font-size-56: 5.6rem;
$font-size-64: 6.4rem;

$font-thin: 100;
$font-extralight: 200;
$font-light: 300;
$font-normal: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;
$font-extrabold: 800;
$font-black: 900;

$line-height-11: 1.1rem;
$line-height-14: 1.4rem;
$line-height-20: 2rem;
$line-height-22: 2.2rem;
$line-height-24: 2.4rem;
$line-height-28: 2.8rem;
$line-height-38: 3.8rem;
$line-height-46: 4.6rem;
$line-height-64: 6.4rem;
$line-height-88: 8.8rem;

$border-radius-md: 5px;

@mixin unify-parent($child) {
  @at-root #{selector.unify(&, $child)} {
    @content;
  }
}

@mixin unify-heading {
  @for $i from 1 through 6 {
    @include unify-parent('h#{$i}') {
      @content;
    }
  }
}

@mixin footnote-12-description($weight: $font-normal) {
  font-size: $font-size-12;
  font-weight: $weight;
  line-height: $line-height-20;
}

@mixin body-14($weight: $font-normal) {
  font-size: $font-size-14;
  font-weight: $weight;
  line-height: $line-height-22;
}

@mixin body-16($weight: $font-normal) {
  font-size: $font-size-16;
  font-weight: $weight;
  line-height: $line-height-24;
}

@mixin small-description-9($weight: $font-medium) {
  font-size: $font-size-9;
  font-weight: $weight;
  line-height: $line-height-11;
}

@mixin subheading-16($weight: $font-normal) {
  font-size: $font-size-16;
  font-weight: $weight;
  line-height: $line-height-24;
}

@mixin subheading-18($weight: $font-medium) {
  font-size: $font-size-18;
  font-weight: $weight;
  line-height: $line-height-22;
}

@mixin heading-20($weight: $font-medium) {
  font-size: 2rem;
  font-weight: $weight;
  line-height: $line-height-28;
}

@mixin heading-24($weight: $font-normal) {
  font-size: 2.4rem;
  font-weight: $weight;
  line-height: 3.2rem;
}

@mixin large-heading-30($weight: $font-medium) {
  font-size: $font-size-30;
  font-weight: $weight;
  line-height: $font-size-38;
}

@mixin large-heading-32($weight: $font-bold) {
  font-size: $font-size-32;
  font-weight: $weight;
  line-height: $font-size-38;
}

@mixin large-heading-38($weight: $font-medium) {
  font-size: $font-size-38;
  font-weight: $weight;
  line-height: $line-height-46;
}

@mixin large-heading-48($weight: $font-medium) {
  font-size: $font-size-48;
  font-weight: $weight;
  line-height: $line-height-64;
}

@mixin large-heading-56($weight: $font-medium) {
  font-size: $font-size-56;
  font-weight: $weight;
  line-height: $line-height-64;
}

@mixin large-heading-64($weight: $font-extrabold) {
  font-size: $font-size-64;
  font-weight: $weight;
  line-height: $line-height-88;
}
