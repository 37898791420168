@import '~@inovirtue/styles';
.group-details-general {
  &__edit-bar {
    margin-bottom: dist(12);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: dist(4);
    background-color: $white;
    padding: dist(8);
    &__text {
      @include body-14();
      color: $color-text-secondary;
    }
  }
}

.cards-container {
  display: flex;
  justify-content: center;

  .ant-card {
    flex-grow: 1;
    margin-right: dist(12);
    margin-bottom: dist(12);
    border-radius: dist(4);
  }
}
