@import '@inovirtue/styles';

.pending-employees {
  &__columns {
    &__name {
      &__name {
        @include body-14($font-medium);
      }
      &__email {
        @include body-14($font-normal);
      }
    }
    &__position {
      @include body-14;
    }
    &__invite-sent {
      @include body-14($font-normal);
    }
  }
}
