@import '@inovirtue/styles';
.group-form-header {
  max-width: 876px;
  &__main {
    @include large-heading-38;
    margin-bottom: dist(12);
  }

  &__sub {
    @include heading-20;
    color: $color-text-secondary;
    margin-bottom: dist(20);
  }
}
