@import '@inovirtue/styles';

.company-contact-delete-form {
  display: flex;
  flex-flow: column;
  gap: $dist-12;
  &__question {
    @include subheading-16(400);
  }
  &__remove-button {
    margin-left: auto;
  }
}
