@import '~@inovirtue/styles';

.providerCalendar {
  min-height: 100vh;
  .rbc {
    &-header {
      border: none;
      font-weight: 400;
    }
    &-off-range-bg {
      background-color: white;
    }
    &-month-view {
      .rbc-month-header {
        margin-bottom: $dist-25;
      }
      border: none;
      .rbc-day-bg,
      .rbc-month-row {
        border: none;
        overflow: visible;
      }
      .rbc-day-bg {
        border-top: 1px solid $gray-4;
        & + .rbc-day-bg {
          margin-left: $dist-4;
        }
        &.rbc-today {
          background-color: white;
          border-color: $tomato-6;
          border-top-width: 2px;
        }
      }
      .rbc-date-cell {
        margin-top: $dist-4;
        margin-right: $dist-4;
        padding: 0;
        &.rbc-current,
        &.rbc-now {
          color: $tomato-6;
          font-weight: bold;
        }
        .rbc-off-range {
          color: $gray-4;
        }
      }
    }
    &-time-view {
      border: none;
      .rbc-time-header-content {
        .rbc-allday-cell {
          display: none;
        }
      }
      .rbc-today {
        background: white;
        &.rbc-header {
          color: $tomato-6;
        }
      }
      .rbc-time-content {
        border-top: none;
        > * + * > * {
          border: none;
        }
      }
      .rbc-time-header-content {
        border-left: none;
      }
      .rbc-time-gutter {
        .rbc-timeslot-group {
          border: none;
          .rbc-time-slot {
            color: $gray-6;
            .rbc-label {
              margin-right: $dist-6;
            }
          }
        }
      }
      .rbc-time-column:not(:nth-child(1)):not(:nth-child(2)) {
        border-left: 1px solid $gray-6;
      }
      .rbc-time-column {
        .rbc-timeslot-group:first-child {
          .rbc-time-slot:first-child {
            border-top: none;
          }
        }
      }
      .rbc-time-column {
        .rbc-timeslot-group:not(:first-child) {
          .rbc-time-slot:first-child {
            border-top-color: $gray-6;
          }
        }
      }
    }
  }

  .rbc-timeslot-group {
    min-height: $dist-50;
  }

  .rbc-row-segment {
    min-height: $dist-25;
    margin-bottom: $dist-3;
    padding-left: $dist-4;
    padding-right: $dist-4;
    display: flex;
    justify-content: center;
  }
}
