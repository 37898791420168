@import '@inovirtue/styles';
.consumerbar {
  min-height: 192px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &__name {
    margin-top: $dist-8;
    @include heading-24(500);
  }

  .email {
    color: $black-45;
    @include body-14;
  }

  &__interests {
    @include heading-20(500);
  }

  &__new-provider-request {
    margin: $dist-16 0;
    &__caption {
      @include body-14(500);
    }
  }
}
