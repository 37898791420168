@import '@inovirtue/styles';

.edit-sessions-modal {
  &__description {
    margin-bottom: $dist-20;
  }

  &__field-label {
    @include subheading-16($font-medium);
    margin-bottom: $dist-8;
  }

  &__after-input {
    margin-left: $dist-4;
  }
}
