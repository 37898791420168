@import '~@inovirtue/styles';

.chat-fullscreen {
  height: 100%;

  .ant-layout-content {
    padding: 0;
  }

  @include desktop {
    padding-bottom: 0;
  }

  &__content {
    flex: 1;
  }
}
